<template>
<div class="community-create">
  <van-cell value="选择文件" />
  <van-uploader v-model="fileList" accept="file" ref="upload" :upload-text="utext" :max-count="5" :disabled="udisabled" :deletable="!udisabled" upload-icon="https://resources.suantan.net/mini-program/index/h5-upload.png" :after-read="afterRead" :before-delete="deleteImg" :preview-full-image="false"   />
  <div class="foot">
    <div class="footer vertical-center" style="justify-content: center;">
    <div class="footer-r vertical-center" @click="sub()" >
      确定
    </div>

  </div>
  <div class="safety-height"></div>
  </div>
  <van-toast id="van-toast" />
</div>
</template>
<script>
import wx from 'weixin-js-sdk';
import _ from 'lodash'
export default {
  name: "community.create",
  data(){
    return{
        fileList: [],
        fileUrl:[],
        utext:'',
        udisabled:false,
    }
  },

    created() {

      
  },
  methods:{
      dianji(){
          this.$refs.upload.chooseFile()
      },
    //提交
    sub: _.debounce(function(){

      wx.miniProgram.navigateBack({
        success:function (){

        }
      }
    )
      wx.miniProgram.postMessage({ data:this.fileUrl})
    },500),
    download(event){
      //下载文件
      let url = event.file
      let filename = event.name
      var a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", filename);
      let clickEvent = document.createEvent("MouseEvents");
      clickEvent.initEvent("click", true, true);
      a.dispatchEvent(clickEvent);
    },
    afterRead(event) {
      this.utext = '正在上传'
      this.udisabled = true
       let data =new FormData()
      data.append('file', event.file)
      data.append('type', 'community')
      this.$http.post(this.$api.uploadresource, data, false).then(res => {
          if(res.data.success){
              this.fileUrl= this.fileUrl.concat(res.data.data)
            this.fileList = JSON.parse(JSON.stringify(this.fileUrl))
            this.utext = ''
            this.udisabled = false
          } else {
            //   wx.showToast({
            //       title: '上传失败',
            //       icon: 'error',
            //       duration: 2000
            //   })
          }
      })
      },
    //      //  删除图片
    deleteImg(event) {
        let list = this.fileList
        let url = this.fileUrl
        list.splice(event.index,1)
        url.splice(event.index,1)
        this.fileList=list
          this.fileUrl=url
    },

  },
  components:{
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/community/create.scss";
.footer-r{
  width: 640/$r!important;
}
</style>
<style lang="scss">
$r: 750/16/1rem;
.van-cell:after{
  border: unset;
}
.cell-l{
  width: 80px;
  flex: none;
}
.van-cell__value{
  text-align: left;
}
   .van-uploader {
      margin: 0 50/$r;
    }
  
    .van-uploader__preview {
      width: 200/$r;
      height: 200/$r;
    }
.van-uploader__file{
  width: 100%;
  height: 100%;
  position: relative;
}
    .van-uploader__preview-image, .van-uploader__upload {
      width: 200/$r!important;
      height: 200/$r!important;
    }
  
</style>